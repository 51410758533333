window.onscroll = function () {
    if (window.scrollY <= 10) {
        let animation = document.getElementById("lottie-logo");
        animation.setDirection(-1);
        animation.play();
    } else {
        let animation = document.getElementById("lottie-logo");
        animation.setDirection(1);
        animation.play();
    }
}

function toggleNav() {
    var hamburger = document.getElementsByClassName('hamburger')[0];
    var menu = document.getElementById('menu');

    hamburger.classList.toggle('open');
    menu.classList.toggle('open');
}